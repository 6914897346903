<template>
    <div>
        <div v-if="!selected">
            <v-toolbar elevation="0">
                <v-toolbar-title>Classes</v-toolbar-title>
            </v-toolbar>
            <v-expansion-panels v-if="courses.length">
                <v-expansion-panel
                v-for="course in courses"
                :key="course.id"
                >
                <v-expansion-panel-header>
                    {{ course.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <p class="text-caption">
                {{ course.code }} | {{ course.start_date }} > {{ course.end_date }}
                </p>
                <p v-html="course.description"></p>
                <v-list>
                    <v-list-item v-for="cl in course.classes" :key="cl.id" link @click="selected = cl">
                        <v-list-item-title>{{ cl.class }}</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card v-else>
                <v-card-text>There are currently no courses / classes configured.</v-card-text>
            </v-card>
        </div>
        <div v-else>
            <v-toolbar elevation="0">
                <v-btn icon @click="selected = ''">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn> 
                <v-toolbar-title></v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-img
                    height="250"
                    src="https://images.unsplash.com/photo-1595446020673-78ed1a43306e?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=800&ixid=MnwxfDB8MXxyYW5kb218MHx8Y2xhc3N8fHx8fHwxNjU0MjEwOTg1&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=800"
                    ></v-img>

                    <v-card-title>{{ selected.class  }}</v-card-title>

                    <v-card-text>

                    <div class="mb-4 text-caption">
                        {{ selected.code }} | {{ selected.starts }} > {{ selected.ends }} ({{selected.start_time}} - {{selected.end_time}})
                    </div>
                    <v-card-actions style="display: inline-table;">
                        <v-btn
                            color="blue lighten-2"
                            @click="studentList"
                            class="mb-3"
                        >
                            Student List
                        </v-btn>
                        <v-btn
                            color="green lighten-2"
                            @click="scores"
                            class="mb-3"
                        >
                            Scores
                        </v-btn>
                        <v-btn
                            color="orange lighten-2"
                            @click="attendance"
                            class="mb-3"
                        >
                            Attendance
                        </v-btn>
                        <v-btn
                            color="red lighten-2"
                            @click="surveys"
                            class="mb-3"
                        >
                            Surveys
                        </v-btn>
                        <v-btn
                            color="yellow lighten-2"
                            @click="notes"
                            class="mb-3"
                        >
                            Notes
                        </v-btn>
                    </v-card-actions>

                    <div class="mt-5" v-html="selected.description"></div>
                    </v-card-text>


            </v-card>
        </div>
    </div>
</template>
<style scoped>
.add {
    bottom: 15px !important;
}
</style>
<script>
  export default {
    data: () => ({
        loading: false,
        selected: '',
        courses: [
        ],
    }),
    methods: {
        loadData: function(){
            var este = this;
            este.loading = true;
            var data = {
                id: this.$store.getters.login.id,
                token: this.$store.getters.login.token,
            }
            this.axios.post(this.$url + 'classes', data).then(function(response){
                window.console.log(response.data.data);
                este.courses = response.data.data;
                este.loading = false;
            }).catch(function(err){
                este.loading = false;
                if(typeof(err.response) !== 'undefined' && err.response.status == 401){
                    este.$store.commit('logout');
                }
                var errMsg;
                if(typeof(err.response) !== 'undefined'){
                    errMsg = err.response.data.msg;
                } else {
                    errMsg = 'There was an error. Please try again.';
                }
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": errMsg
                }
                este.$store.commit("msgMuestra", mensaje);
            });
        },
        studentList: function(){},
        attendance: function(){},
        surveys: function(){},
        scores: function(){},
        notes: function(){},
    },
    mounted: function(){
        this.loadData();
    }
  }
</script>
